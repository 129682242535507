export const RelatedToAllegro = {
  clientEntityState: 4,
  tenantId: '',
  id: '',
  idRelatedToAllegroType: '',
  sellerId: '',
  channel: '',
  deliveryMethod: '',

};
