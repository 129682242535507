<template>
  <configuration-set
    v-if="!!getConfigurationSet()?.courier"
    :components="components"
    :speditor="speditor"
    :current-component="currentComponent"
    :loading="loading"
    @@changeComponent="changeComponent"
    @@save="save"
  >
    <template v-slot:content>
      <ServiceAuthorization
        v-show="currentComponent==='ServiceAuthorization'"
        :is-new="isNew"
      />

      <AdditionalServices
        v-show="currentComponent==='AdditionalServices'"
        :is-new="isNew"
      />

      <AdditionalFields
        v-show="currentComponent==='AdditionalFields'"
        :is-new="isNew"
      />

      <ContentAndComments
        v-show="currentComponent==='ContentAndComments'"
        :is-new="isNew"
      />
    </template>
  </configuration-set>
</template>

<script>
import ServiceAuthorization from '@/views/Speditors/PocztaPolska/Forms/ServiceAuthorization.vue';
import { PocztaPolska } from '@/constants/Speditors/PocztaPolska/configuration/PocztaPolska';
import AdditionalServices from '@/views/Speditors/PocztaPolska/Forms/AdditionalServices.vue';
import ContentAndComments from '@/views/Speditors/PocztaPolska/Forms/ContentAndComments.vue';
import AdditionalFields from '@/views/Speditors/PocztaPolska/Forms/AdditionalFields.vue';
import ConfigurationSet from '@/components/shared/ConfigurationSet.vue';
import configurationMixin from '@/mixins/configurationMixin';

export default {
  components: {
    ConfigurationSet,
    ServiceAuthorization,
    AdditionalServices,
    ContentAndComments,
    AdditionalFields,
  },
  mixins: [configurationMixin],
  data: () => ({
    speditor: 'PocztaPolska',
    currentComponent: 'ServiceAuthorization',
    components: [
      { code: 'ServiceAuthorization', name: 'courierSet.serviceAuthorizationName' },
      { code: 'AdditionalServices', name: 'courierSet.additionalServices' },
      { code: 'ContentAndComments', name: 'courierSet.contentAndComments' },
      { code: 'AdditionalFields', name: 'courierSet.additionalFields' },
    ],
  }),
  methods: {
    setNewConfiguration() {
      this.setConfigSet({ response: PocztaPolska });
    },
  },
};
</script>
