import { PocztaPolskaDefaultValues } from './PocztaPolskaDefaultValues';

export const PocztaPolska = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  clientId: '',
  configurationName: '',
  password: '',
  metadata: [],
  courier: '',
  courierCode: 'pocztapolska',
  integration: 'POCZTA_POLSKA',
  userName: '',
  numerRachunkuBankowego: '',
  urlAdress: '',
  service: '',
  zasadySpecjalne: '',
  masa: '',
  gabaryt: '',
  wartosc: '',
  ostroznie: '',
  numerTransakcjiOdbioru: '',
  additionalServices: { services: [] },
  fieldsMappings: { mappings: [] },
  pocztaPolskaDefaultValues: PocztaPolskaDefaultValues,
};
